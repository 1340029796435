import React, { ComponentPropsWithRef, ComponentPropsWithoutRef, forwardRef } from 'react';

import classNames from 'classnames';

import { ArrowLeft, ArrowRight } from '@/blocks/components/Icons';
import { ChevronLeft, ChevronRight } from '@/blocks/components/Icons/Chevron';

import styles from './styles.module.scss';

const arrowVariants = {
  arrow: styles.arrow,
  chevron: styles.chevron,
};

const arrowIcons = {
  arrow: { prev: <ArrowLeft />, next: <ArrowRight /> },
  chevron: { prev: <ChevronLeft />, next: <ChevronRight /> },
};

interface SlideArrowButtonProps extends ComponentPropsWithRef<'button'> {
  variant?: keyof typeof arrowVariants;
  direction: 'prev' | 'next';
}

type SlidePrevNextProps = Omit<SlideArrowButtonProps, 'direction'>;

// eslint-disable-next-line react/display-name
const SlideArrowButton = forwardRef<HTMLButtonElement, SlideArrowButtonProps>((props, ref) => {
  const { className, variant = 'arrow', direction, ...rest } = props;

  return (
    <button ref={ref} className={classNames(styles.arrowButton, arrowVariants[variant], className)} {...rest}>
      {arrowIcons[variant][direction]}
    </button>
  );
});

export const SlideNext = forwardRef<HTMLButtonElement, SlidePrevNextProps>((props, ref) => (
  <SlideArrowButton ref={ref} {...props} direction="next" aria-label="Next slide" />
));

SlideNext.displayName = 'SlideNext';

export const SlidePrev = forwardRef<HTMLButtonElement, SlidePrevNextProps>((props, ref) => (
  <SlideArrowButton ref={ref} {...props} direction="prev" aria-label="Previous slide" />
));

SlidePrev.displayName = 'SlideNext';
